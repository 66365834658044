import * as React from "react";
import Header from "../components/header";
import Newsletter from "../components/newsletter";
import Footer from "../components/Footer";
import BannerEffect from "../themes/custom/jobs_front/assets/img/bannerEffect.png";
import TunisiaJobs from "../assets/img/assistance.jpg";
import TunisiaJobs15 from "../assets/img/product_developement_1.jpg";
import TunisiaJobs16 from "../assets/img/product_developement_2.jpg";
import TunisiaJobs17 from "../assets/img/product_developement_3.jpg";
import TunisiaJobs18 from "../assets/img/product_developement_4.jpg";

const AssistanceToEnterprises = () => {
  return (
    <>
      <Header />
      <main role="main">
        <div className="container-fluid">
          <div className="region region-banner">
            <div
              id="block-assistancetoentreprises"
              className="block block-block-content block-block-contentbbbe56a9-7411-4d30-b6b6-041089750357"
            >
              <div className="intern-banner">
                <div className="overlay">
                  <img src={BannerEffect} alt="" />
                </div>
                <img src={TunisiaJobs} alt="" />
                <div className="caption">
                  <h1 className="animate__animated animate__fadeInUp animate__slow">
                    <div className="field field--name-field-titre field--type-string field--label-hidden field__item">
                      Product Development
                    </div>
                  </h1>
                  <div className="animate__animated animate__fadeInUp animate__slow animate__delay-1s">
                    <div className="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item">
                      <p>
                        Tapping into Tunisia’s{" "}
                        <span>vast natural and cultural resources.</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="region region-content">
            <div data-drupal-messages-fallback className="hidden" />
            <div
              id="block-jobs-front-content"
              className="block block-system block-system-main-block"
            >
              <article
                data-history-node-id={48}
                role="article"
                about="/en/Assistance_To_Enterprises"
                className="node node--type-activities node--promoted node--view-mode-full"
              >
                <div className="node__content">
                  <div className="field field--name-field-bricks field--type-bricks field--label-hidden field__items">
                    <div>
                      <section className="sector-vision">
                        <div className="container">
                          <div className="row">
                            <div className="card-wrapper">
                              <div
                                className="card aos-animate"
                                data-aos="fade-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={200}
                              >
                                <div className="inner-card">
                                  <h3>THE SECTOR</h3>
                                  <p>
                                    Tunisia has a wide range of stunning
                                    attractions – from desert to lush mountains
                                    to millennia-old archaeological relics --
                                    making it a unique tourism destination at
                                    the crossroads of Europe, Africa, and the
                                    Middle East. It can fully capitalize on
                                    these endowments by developing new
                                    sustainable tourism offerings throughout the
                                    country, particularly in less-visited,
                                    underserved regions.
                                  </p>
                                </div>
                              </div>
                              <div
                                className="card aos-animate"
                                data-aos="fade-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay
                              >
                                <div className="inner-card">
                                  <h3>VISIT TUNISIA’S VISION AND STRATEGY</h3>
                                  <p>
                                    The USAID Visit Tunisia Activity supports
                                    the development of high-quality, sustainable
                                    products and experiences that highlight the
                                    country's vast heritage and natural beauty.
                                    We work to enhance cultural and natural
                                    sites, organize cultural events and
                                    festivals, and develop new interactive
                                    tourism experiences, with a special focus on
                                    engaging women and youth in local
                                    communities.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section className="resources" id="grants">
                        <div className="container">
                          <h2
                            className="title-left aos-animate"
                            data-aos="fade-up"
                          >
                            <div className="clearfix text-formatted field field--name-field-title field--type-text field--label-hidden field__item">
                              Our Work
                            </div>
                          </h2>
                          <div
                            className="section-paragh aos-animate"
                            data-aos="fade-up"
                          >
                            <div className="d-flex flex-md-row flex-column">
                              <div className="left-side">
                                <p>
                                  Visit Tunisia creates experiences along with
                                  partners in premier sites, engaging local
                                  tourism businesses and communities in offering
                                  authentic products (like festivals and events)
                                  promoted directly to consumers and through
                                  operators.
                                </p>
                              </div>
                              <div className="right-side d-flex justify-content-md-end">
                                <div className="ticket">
                                  <h3>
                                    <a href="/where-we-work" target="_blank">
                                      Where We Work
                                    </a>
                                  </h3>
                                  <a
                                    className="underlined-link"
                                    href="/where-we-work"
                                    target="_blank"
                                  >
                                    Learn More{" "}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="grants-section"
                            style={{ height: "60%", marginTop: "25px" }}
                          >
                            <div className="d-flex flex-wrap">
                              <div
                                className="item work-card aos-animate our-work-image"
                                style={{ position: "relative" }}
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={0}
                              >
                                {/* <div className="overlay">
                                  <img
                                    src={BannerEffect}
                                    alt=""
                                  />
                                </div> */}
                                <img
                                  src={TunisiaJobs15}
                                  alt=""
                                  style={{ filter: "brightness(70%)" }}
                                />
                                <div className="card-text">
                                  <h3>
                                    Destination Development Planning Support
                                  </h3>
                                  <div className="card-info justify-content-between align-items-center">
                                    <span>
                                      We support Tunisia’s regional tourism
                                      offices (CRTs) and municipalities to
                                      engage the private sector to create
                                      destination development plans that will
                                      increase investment and visitors to
                                      underserved regions.{" "}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="item work-card aos-animate  our-work-image"
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={100}
                                style={{ position: "relative" }}
                              >
                                {/* <div className="overlay">
                                  <img
                                    src={BannerEffect}
                                    alt=""
                                  />
                                </div> */}
                                <img
                                  src={TunisiaJobs16}
                                  alt=""
                                  style={{ filter: "brightness(70%)" }}
                                />
                                <div className="card-text">
                                  <h3>Tourism Product Development Support</h3>
                                  <div className="card-info justify-content-between align-items-center">
                                    <span>
                                      We encourage and facilitate public private
                                      partnerships and private sector
                                      collaboration to develop new tourism
                                      products, services, and experiences.
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="item work-card aos-animate  our-work-image"
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={200}
                                style={{ position: "relative" }}
                              >
                                {/* <div className="overlay">
                                  <img
                                    src={BannerEffect}
                                    alt=""
                                  />
                                </div> */}
                                <img
                                  src={TunisiaJobs17}
                                  alt=""
                                  style={{ filter: "brightness(70%)" }}
                                />
                                <div className="card-text">
                                  <h3>
                                    Event and Festival Development Support
                                  </h3>
                                  <div className="card-info justify-content-between align-items-center">
                                    <span>
                                      We support Tunisian event and festival
                                      organizers to plan and implement engaging,
                                      interactive events and festivals that
                                      showcase Tunisia’s diverse cultural
                                      heritage.{" "}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="item work-card aos-animate  our-work-image"
                                data-aos="zoom-in-up"
                                data-aos-easing="ease-in-back"
                                data-aos-delay={200}
                                style={{ position: "relative" }}
                              >
                                {/* <div className="overlay">
                                  <img
                                    src={BannerEffect}
                                    alt=""
                                  />
                                </div> */}
                                <img
                                  src={TunisiaJobs18}
                                  alt=""
                                  style={{ filter: "brightness(70%)" }}
                                />
                                <div className="card-text">
                                  <h3>
                                    Tourism Infrastructure Development Support
                                  </h3>
                                  <div className="card-info justify-content-between align-items-center">
                                    <span>
                                      We help mobilize investment and improve
                                      visitor experience at select cultural
                                      sites.{" "}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      {/* <section
                        className="impact aos-animate"
                        data-aos="fade-up"
                      >
                        <div className="brick brick--type--impact brick--id--54 eck-entity container">
                          <span className="title-left">
                            <div className="field field--name-field-subtitle field--type-string field--label-hidden field__item">
                              OUR GOALS
                            </div>
                          </span>
                          <h2></h2>
                          <ul className="pl-0">
                            <li>
                              <span>
                                Increase overnight stays in underserved regions
                                by 20%
                              </span>
                            </li>
                            <li>
                              <span>
                                Develop or enhance six events/festivals that
                                each draw more than 1,000 attendees
                              </span>
                            </li>
                            <li>
                              <span>
                                Increase visitors by 20% to targeted sites and
                                facilities
                              </span>
                            </li>
                            <li>
                              <span>
                                Renovate and upgrade 10 sites and facilities and
                                support the development of 30 new tourism
                                products, services and experiences
                              </span>
                            </li>
                          </ul>
                        </div>
                      </section> */}
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </main>
      <Newsletter />
      <Footer />
    </>
  );
};
export default AssistanceToEnterprises;
